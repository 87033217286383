import { template as template_7771dea333f04a71bf46ee308afa28af } from "@ember/template-compiler";
const SidebarSectionMessage = template_7771dea333f04a71bf46ee308afa28af(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;

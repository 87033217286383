import { template as template_07683b297f0b4f18a9f862e2f66eb6ff } from "@ember/template-compiler";
const FKControlMenuContainer = template_07683b297f0b4f18a9f862e2f66eb6ff(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;

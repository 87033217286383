import { template as template_801c0a4d0bde464b8cee4ac8b9def823 } from "@ember/template-compiler";
const WelcomeHeader = template_801c0a4d0bde464b8cee4ac8b9def823(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
